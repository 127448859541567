<template>
  <Notifications/>
</template>

<script>
export default {
  name: 'notifications',
  components: {
    Notifications: () => import("@/components/notifications/Notifications"),
  }
};
</script>